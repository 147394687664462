import { Component, HostListener } from '@angular/core';
import { BreakpointService } from './appUtilities/breakpoint.service';
import { AppInitializerService } from './core/appInit/app-initializer.service';
// import { skipInactivityUrls } from './appConstants/skipInactivityUrls';
// import { PreviousRouteService } from './appServices/previous-route/previous-route.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  // standbyTime:number = 1800; // In Seconds (30Min)
  // skipStandbyScreen: boolean = false;

  breakpoint$ = this.breakpointService.breakpoint;

  constructor(
    private breakpointService: BreakpointService,
    private appInitService: AppInitializerService
  ) {
    this.appInitService.initializeApp();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.breakpointService.onResize(event.target.innerWidth);
  }



}
