// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: 'https://main-api.preprod-tastio.com/app/v1/',
  backupBaseUrl: 'https://main-api.preprod-tastio.com/app/v1/',
  tenantApi: 'https://tenant-api.preprod-tastio.com/app/v1',
  backupTenantApi: 'https://tenant-api.preprod-tastio.com/app/v1',
  S3_URL: 'https://tastio-testing.ams3.digitaloceanspaces.com/',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
