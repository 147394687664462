import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(public toastController: ToastController) { }

  async errorToastWithClose(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'danger',
      duration: 5000,
      cssClass:'toast',
      mode: 'ios',
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }
      ]
    });
    await toast.present();
  }

  async successToastWithClose(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'success',
      duration: 2000,
      cssClass:'toast',
      mode: 'ios',
      buttons: [{
        text: 'Close',
        role: 'cancel'
      }
      ]
    });
    await toast.present();
  }

  async successToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'success',
      duration: 2000,
      mode: 'ios',
      cssClass:'toast',
    });
    await toast.present();
  }

  async errorToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'danger',
      duration: 2000,
      mode: 'ios',
      cssClass:'toast',
    });
    await toast.present();
  }

  closeToaster() {
    this.toastController.dismiss();
  }

  async toast(message: string, button: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      duration: 5000,
      cssClass:'toast',
      mode: 'ios',
      buttons: [
        {
          side: 'end',
          text: button,
          role: 'button',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await toast.present();

    // const { role } = await toast.onDidDismiss();
    return await toast.onDidDismiss();
  }

}
