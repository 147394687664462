import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, tap, takeUntil } from 'rxjs/operators';
import { IRoute } from 'src/app/appInterfaces/IRoute';
import { IOrderModel, IOrderStatusModel } from 'src/app/appInterfaces/IOrderModel';
import { KdsService } from 'src/app/appServices/kds/kds.service';
import { OrderService } from 'src/app/appServices/order.service';
import { DateTimeUtility } from 'src/app/appUtilities/dateTime.utility';
import { OrderStatus, OrderType } from 'src/app/appEnums/enums';
import { AudioService } from '../audio.service';
import { IStore } from 'src/app/appInterfaces/IStore';
import { StoreResolver } from '../store/store.resolver';

@Injectable({
  providedIn: 'root'
})
export class KdsResolver {
  orders = new BehaviorSubject<any[]>(null);
  loading = new BehaviorSubject<boolean>(false);
  routes = new BehaviorSubject<IRoute[]>(null);
  selectedRoute = new BehaviorSubject<IRoute>(null);
  selectedStatus = new BehaviorSubject<IOrderStatusModel>(null);
  availableStausList: IOrderStatusModel[] = new Array();
  enableOrderSound = new BehaviorSubject<boolean>(true);
  selectedStore: IStore;

  destroy$ = new Subject();
  orderStream$ = new Subject();

  constructor(
    private kdsService: KdsService,
    private _orderService: OrderService,
    private _audioService: AudioService,
    private _storeResolver: StoreResolver,
    private dateUtility: DateTimeUtility) {
  }

  /**
   * Init Apis
   */
  initApis(){
    this.subscribeOrderStream();
    this.getAllRoutes();
    this.getAllAvailableStatusList();
    this.subscribeSelectedRoutes();
    this.subscribeSelectedStatus();
    this.subscribeSelectedStore();
    this.startOrderTimer();
  }


  /**
   * Call order stream
   */
  callOrderStream(){
    this.orderStream$.next(null);
  }

  /**
   * Subscribe order stream
   */
  subscribeOrderStream(){
    this.orderStream$.pipe(
        debounceTime(500),
        switchMap( () => this.getOrders())
      ).subscribe(res=>{
      this.setOrders(res);
    })
  }


  /**
   * Subscribe selected store
   */
   subscribeSelectedStore(){
    this._storeResolver.selectedStore.pipe(
      takeUntil(this.destroy$),
      tap( (res)=>  {
        this.selectedStore = res;
        this.orders.next(null)
      }),
      switchMap( (data) => this.getOrders()),
    )
    .subscribe(res => {
      this.setOrders(res);
    });


    
  }


  /**
   * Toggle Order Sound
   */
  toggleOrderSound(){
    this.enableOrderSound.next(
      (this.enableOrderSound?.value) ? false: true
    )
  }


  /**
   * get all routes
   */
  getAllRoutes() {
    this.kdsService.getAllRoutes().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.routes.next(res);
      if(!this.selectedRoute.value){
        this.setSelectedRoute(this.routes.value[0]);
        this.selectedStatus.next(this.routes.value[0].orderStatusList[0]);
      }
    });
  }



  /**
   * Set Selected route
   * @param route 
   */
   setSelectedRoute(route:IRoute){
    this.selectedRoute.next(route);
  }



  /**
   * subscribe selected routes 
   */
   subscribeSelectedRoutes() {
    this.selectedRoute.pipe(
      takeUntil(this.destroy$),
      tap( ()=>  this.orders.next(null)),
      switchMap( (data) => this.getOrders()),
    )
    .subscribe(res => {
      this.setOrders(res);
    });
  }



  /**
   * subscribe selected status 
   */
   subscribeSelectedStatus() {
    this.selectedStatus.pipe(
      takeUntil(this.destroy$),
      switchMap( (data) => this.getOrders() ),
    ).subscribe((res) => {
      this.setOrders(res);
    });

  }




  /**
   * set orders
   */
  setOrders(orders){
    if(!this.orders.value){
      this.orders.next(orders);
      this.setOrdersTimer(this.orders.value);
    }else{
      orders.forEach(order => {
        let tempOrder = this.orders.value.find(e=> e.id== order.id);
        if(!tempOrder){
          if (this.enableOrderSound.value) {
            this._audioService.playAudio('notifyBeep');
          }
        }else{
          tempOrder = order;
        }
      });
      this.orders.next(orders);
    }
  }




  /**
   * Refresh Orders
   */
   refreshOrders(){
    this.loading.next(true);
    this.getOrders().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.setOrders(res);
      this.loading.next(false);
    });
  }



  /**
   * Order Interval
   */
  orderTimerSub: any;
  startOrderTimer(){
    if(this.orderTimerSub){
      return;
    }
    const timerObs = timer(10000, 10000);
    this.orderTimerSub = timerObs.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.refreshOrders();
    });
  }




  /**
   * Unsubscrbe order interval
   * @returns 
   */
  unsubscribeOrderInterval(){
    this.orderTimerSub.unsubscribe();
    this.orderTimerSub = null;
  }


  

  /**
   * get orders
   * @returns 
   */
  getOrders(): Observable<IOrderModel[]> {
    if(this.selectedRoute.value?.name && this.selectedStatus.value?.status && this.selectedStore?.id){
      return this.kdsService.getOrdersByRouteAndStatus(this.selectedRoute.value.name, this.selectedStatus.value.status, this.selectedStore?.id).pipe(debounceTime(500))
    }
    return of(null);
  }
  



  /**
   *  
   */
  setOrdersTimer(orders:any[]) {
    if (orders) {
      orders.forEach((x) => {
        // this.setCardColor(hoursTimer, minuteTimer, x);
      });
    }
  }

  

  




  




  /**
   * mark order to next state
   * @param status
   */
  getNextStatus(status: string, orderType: string): string {
    switch (status) {
      case OrderStatus.Pending:
        return 'Accepted';

      case OrderStatus.Accepted:
        return 'Prepared';

      case OrderStatus.Prepared:
        return OrderType.Delivery === orderType ? 'On The Way' : 'Delivered';

      case OrderStatus.OnTheWay:
        return 'Delivered';

      case OrderStatus.Pending:
        return 'Accepted';

      default:
        break;
    }
  }




  /**
   *Get all available Status List from API 
   */
  getAllAvailableStatusList() {
    this.kdsService.getOrderStatusList().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.availableStausList = res;
    });
  }


  

  /**
   * Update Order Status
   * @param order 
   * @returns 
   */
  updateOrderStatus(order:any):Observable<any>{
    return this._orderService.updateOrderStatus(order);
  }

  
  /**
   * Destroy subscriptions
   */
  destroySubscriptions() {
    this.destroy$.next();
    this.destroy$.unsubscribe(); 
    this.destroy$ = new Subject();

    this.orderStream$.unsubscribe(); 
    this.orderStream$ = new Subject();
  }
  
  

}
