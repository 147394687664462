import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  api = this.apiConfig.getBaseUrl;

  constructor(private apiConfig: HttpConfigService) {
  }

  /**
   * get all stores info
   */
  getAllStores(): Observable<any[]> {
    return this.apiConfig.get(this.api + "store");
  }

  /**
   * get all order types 
   */
  getAllOrderTypes(storeId: number): Observable<any[]> {
    return this.apiConfig.get(`${this.api}store/orderTypes/cashier/${storeId}`);
  }

}
