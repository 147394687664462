import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProfile } from 'src/app/appModels/IProfile';
import { GlobalAuthServices } from './global-auth.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalAuthResolver {
  
  profile = new BehaviorSubject<IProfile>(null); 

  loggedIn = new BehaviorSubject<boolean>(false);

  isLoggedIn() {
    return this.loggedIn.value;
  }

  // hasRole(role: IRole) {
  //   return this.isLoggedIn() && this.profile.value?.role === role;
  // }

  /**
   * Get current role
   * @returns 
   */
  // get role() {
  //   return this.isLoggedIn() && this.profile.value?.role
  // }

  // get isAdmin() {
  //   return this.hasRole(IRole.Admin);
  // }

  constructor(
    private _globalAuthService: GlobalAuthServices,
    ) {
  }
    


  /**
   * Get Profile
   * @returns 
   */
  getProfile(){
    this._globalAuthService.getProfile().subscribe(res=>{
      this.profile.next(res);
    });
  }

}
