import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';


@Injectable({
  providedIn: 'root'
})
export class GlobalAuthServices {

  api = this.apiConfig.getBaseUrl;
  constructor(
    private apiConfig: HttpConfigService) { }

  /**
   * Get Profile
   * @returns 
   */
  getProfile(): Observable<any> {
    return this.apiConfig.get(`${this.api}user/profile`);
  }


  
}
