import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  api = this.apiConfig.getBaseUrl;


  constructor(private apiConfig: HttpConfigService) { }



  removeOrderById(id): Observable<any> {
    return this.apiConfig.delete(this.api + 'order/' + id, {
      withCredentials: true,
    });
  }

  sendReceipt(id, data): Observable<any> {
    return this.apiConfig.post(this.api + 'order/send-receipt/' + id, data, {
      withCredentials: true,
    });
  }


  getOrderById(id): Observable<any> {
    return this.apiConfig.get(this.api + 'order/admin/' + id, {
      withCredentials: true,
    });
  }




  updateOrderStatus(order): Observable<any> {
    return this.apiConfig.put(this.api + 'order/update/status', order, {
      withCredentials: true,
    });
  }


}
