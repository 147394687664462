import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class RestaurantInfoService {
  api = this.apiConfig.getBaseUrl + "account-settings";

  constructor(private apiConfig: HttpConfigService) {
  }

  getBasicInfo(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

}
